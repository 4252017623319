<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>时间：</span>
                </div>
                <div class="baseSearchOneRight2 clearfloat">
                    <!--<div style="height: 100%;float: left;font-size: 0;">-->
                    <!--<el-radio-group v-model="searchForm.radio" size="mini">-->
                    <!--<el-radio-button label="1">今日</el-radio-button>-->
                    <!--<el-radio-button label="2">昨日</el-radio-button>-->
                    <!--<el-radio-button label="3">近七日</el-radio-button>-->
                    <!--</el-radio-group>-->
                    <!--</div>-->
                    <div style="height: 100%;float: left;font-size: 0;margin-left: 10px;">
                        <el-date-picker v-model="searchForm.time" type="daterange" unlink-panels
                                        range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                        value-format="YYYY-MM-DD"></el-date-picker>
                    </div>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>操作类型：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.type" placeholder="请选择" clearable>
                        <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>模块：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.module" placeholder="请选择" clearable>
                        <el-option v-for="item in moduleList" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openExport">导出</el-button>
            </div>
        </div>
        <div class="baseTable" v-loading="tableLoading">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                         ref="publicTableUserStudent">
                <el-table-column prop="created_at" label="操作时间"></el-table-column>
                <el-table-column prop="user.name" label="操作人"></el-table-column>
                <el-table-column prop="module" label="操作模块"></el-table-column>
                <el-table-column prop="type" label="操作详情"></el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import XLSX from 'xlsx';

    import {businessGet} from '../../api/business.js'

    import {utilsExportExcel} from '../../utils/utils.js'

    export default {
        name: "systemOperate",
        components: {
            publicPage,
            publicTable
        },
        data() {
            return {
                moduleList: [],
                typeList: [],
                shortcuts: [
                    {
                        text: '昨日',
                        value: () => {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                            return [start, end]
                        },
                    },
                    {
                        text: '最近一周',
                        value: () => {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            return [start, end]
                        },
                    },
                    {
                        text: '最近一个月',
                        value: () => {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            return [start, end]
                        },
                    },
                ],
                searchForm: {
                    time: [],
                    type: '',
                    module: '',
                },
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕',
                    },
                    {
                        value: '选项2',
                        label: '双皮奶',
                    },
                    {
                        value: '选项3',
                        label: '蚵仔煎',
                    },
                    {
                        value: '选项4',
                        label: '龙须面',
                    },
                    {
                        value: '选项5',
                        label: '北京烤鸭',
                    },
                ],
                searchHeight: 300,
                tableLoading: false,
                tableData: [],
                total: 0,
                ruleForm: {
                    name: '',
                    num: 0,
                },
                rules: {
                    name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
            }
        },
        mounted() {
            this.getModuleList();
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.getTableData()
            })
        },
        methods: {
            openExport() {
                let url = '/admin/v1/operation?is_export=1';
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                utilsExportExcel(url)
            },
            exportExcel() {
                //数据表格
                var aoa = [
                    ['表格在此', '', '', ''],
                    ['', '', '', ''],
                    ['姓名', '性别', '年龄', '注册时间'],
                    ['张三', '男', 18, new Date()],
                    ['李四', '女', 22, new Date()]
                ];

                //创建book
                var wb = XLSX.utils.book_new();

                var ws = XLSX.utils.aoa_to_sheet(aoa);
                //设置列宽
                ws['!cols'] = [
                    {width: 50},
                    {width: 15},
                    {width: 15},
                    {width: 10}
                ];
                ws['!merges'] = [
                    // 设置A1-C1的单元格合并
                    {
                        s: {r: 0, c: 0},
                        e: {r: 1, c: 3}
                    }
                ];

                ws['A3'].l = {Target: "http://sheetjs.com", Tooltip: "Find us @ SheetJS.com!"};

                var aoa2 = [
                    ['姓名', '性别', '年龄', '注册时间'],
                    ['张三', '男', 18, new Date()],
                    ['李四', '女', 22, new Date()]
                ];

                var ws2 = XLSX.utils.aoa_to_sheet(aoa2);

                ws2["A1"].s = {
                    font: {sz: 14, bold: true, vertAlign: true},
                    alignment: {vertical: "center", horizontal: "center"},
                    fill: {bgColor: {rgb: "E8E8E8"}, fgColor: {rgb: "E8E8E8"}}
                };

                var aoa3 = [
                    ['姓名', '性别', '年龄', '注册时间'],
                    ['张三', '男', 18, new Date()],
                    ['李四', '女', 22, new Date()]
                ];

                var ws3 = XLSX.utils.aoa_to_sheet(aoa3);
                ws3["!margins"] = {left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3}

                //sheet写入book
                XLSX.utils.book_append_sheet(wb, ws, "操作合并，列宽");
                XLSX.utils.book_append_sheet(wb, ws2, "正常");
                XLSX.utils.book_append_sheet(wb, ws3, "操作间隔");

                //输出

                var timestamp = (new Date()).getTime();
                XLSX.writeFile(wb, "file" + timestamp + ".csv");
            },
            cleanAll() {
                this.searchForm.time = [];
                this.searchForm.type = '';
                this.searchForm.module = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/operation?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        if (k == 'time') {
                            if (this.searchForm[k][0] && this.searchForm[k][1]) {
                                url = url + '&' + k + '=' + this.searchForm[k][0] + ' 00:00:00 - ' + this.searchForm[k][1] + ' 23:59:59';
                            }
                        }
                        else {
                            url = url + '&' + k + '=' + this.searchForm[k];
                        }
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.list;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getModuleList() {
                let url = '/admin/v1/op_condition';
                businessGet(url).then(res => {
                    setTimeout(() => {
                        if (res.data.status == 200) {
                            let moduleList = [];
                            for (let k in res.data.data.module) {
                                let a = {
                                    value: k,
                                    label: res.data.data.module[k]
                                }
                                moduleList.push(a)
                            }
                            this.moduleList = moduleList;
                            let typeList = [];
                            for (let k in res.data.data.type) {
                                let a = {
                                    value: k,
                                    label: res.data.data.type[k]
                                }
                                typeList.push(a)
                            }
                            this.typeList = typeList;
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    }, 500)
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

</style>
